const transactionHistory = (request) => {
    return new Promise((res, rej) => {
        axios.post('getalltransactions', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const searchtransactionHistory = (request) => {
    return new Promise((res, rej) => {
        axios.post('searchtransaction', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getparticularTransaction = (request) => {
    return new Promise((res, rej) => {
        axios.post('/getsingletransactiondetails', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getReturnTransactions = () => {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
    return new Promise((res, rej) => {
        axios.get("/getreturns")
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const representTransaction = (request) => {
    return new Promise((res, rej) => {
        axios.post('/representtransaction', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const representDebit = (request) => {
    return new Promise((res, rej) => {
        axios.post('/representmanualbanktransaction', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getHistoricalTransaction = (request) => {
    return new Promise((res, rej) => {
        axios.post('/historicaltransactions', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getRepaymentEnabledDates = () => {
    return new Promise((res, rej) => {
        axios.get("getenablerescheduledays")
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};


const exportHistoricalTransaction = (request) => {
    var header = {
        responseType: 'blob',
    };
    return new Promise((res, rej) => {
        axios.post('/reports/consumerHistrocalTransactionExport', request, header)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const fetchBankAccountDetails = (request) => {
    return new Promise((res, rej) => {
        axios.post("fetchbankaccountdetails", request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const searchHistoricaltransactionHistory = (request) => {
    return new Promise((res, rej) => {
        axios.post('searchhistoricaltransaction', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const exportTransaction = (request) => {
    var header = {
        responseType: 'blob',
    };
    return new Promise((res, rej) => {
        axios.post('/reports/consumerTransactionExport', request, header)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getMofifiedTransaction = () => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
        axios.get("getallmodifiedtransactions")
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getPendingTransactionAmount = () => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
        axios.get("getpendingtransactionamount")
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getPendingTransactionDetails = () => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
        axios.get("getpendingtransactiondetails")
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const checkTransactionDisabled = (request) => {
    return new Promise((res, rej) => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("consumer_token");
        axios.post("checktransactiondisabled", request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
export default {
    transactionHistory,
    searchtransactionHistory,
    getparticularTransaction,
    getReturnTransactions,
    representTransaction,
    representDebit,
    getHistoricalTransaction,
    exportHistoricalTransaction,
    searchHistoricaltransactionHistory,
    exportTransaction,
    getRepaymentEnabledDates,
    fetchBankAccountDetails,
    getMofifiedTransaction,
    getPendingTransactionAmount,
    getPendingTransactionDetails,
    checkTransactionDisabled
};